import { Box, Typography, Stack, useTheme } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useUser from 'store/user/useUser';
import { getCookie } from 'utils/cookie';

import DisplayInformation from './DisplayInformation';
import InputInformation from './InputInformation';
import MenuDrawer from './MenuDrawer';
import Introduction from './Introduction';

import { CoachMark, ICoachProps } from 'react-coach-mark';

import { ReactComponent as Number01 } from 'assets/icon-number-01.svg';
import { ReactComponent as Number02 } from 'assets/icon-number-02.svg';
import { ReactComponent as Number03 } from 'assets/icon-number-03.svg';
import { ReactComponent as Number04 } from 'assets/icon-number-04.svg';
import { ReactComponent as Number05 } from 'assets/icon-number-05.svg';
import Channel from '../../Channel';

function Main() {
  const { palette } = useTheme();

  const navigate = useNavigate();
  const {
    student_name,
    student_uuid,
    student_grade,
    college_ci,
    college_name,
  } = useUser();

  useEffect(() => {
    if (student_uuid) return;
    navigate('/signin', { replace: true });
  }, [student_uuid, navigate]);

  useEffect(() => {
    Channel.boot({
      pluginKey: `${process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY}`,
    });

    Channel.setPage('page');

    return () => {
      Channel.shutdown();
    };
  }, []);

  const title = student_grade === 1 ? 'Nurse' : '(Prof./TA)';
  const [menuDrawerWidth, setMenuDrawerWidth] = useState(220);

  /* Introduction 관련 */
  const [open, setOpen] = useState(false);
  /********************/

  /* 코치마크 관련 */
  const [show, setShow] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const [activatedNumber, setActivateNumber] = useState<number>(0);
  const NextButton = (
    <button onClick={() => setActivateNumber(activatedNumber + 1)}>
      Got it
    </button>
  );

  const coachList: Array<ICoachProps> = [
    {
      activate: activatedNumber === 0,
      component: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Number01 fill={palette.primary.main} />
          <Typography sx={{ display: 'flex' }}>
            Tap here to select a virtual patient
          </Typography>
          {NextButton}
        </Stack>
      ),
      reference: ref1,
      tooltip: { position: 'bottom' },
    },
    {
      activate: activatedNumber === 1,
      component: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Number02 fill={palette.primary.main} />
          <Typography sx={{ display: 'flex' }}>
            Various records are available!
          </Typography>
          {NextButton}
        </Stack>
      ),
      reference: ref2,
      tooltip: { position: 'right' },
    },
    {
      activate: activatedNumber === 2,
      component: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Number03 fill={palette.primary.main} />
          <Typography sx={{ display: 'flex' }}>
            Tap “V” to see more records
          </Typography>
          {NextButton}
        </Stack>
      ),
      reference: ref3,
      tooltip: { position: 'right' },
    },
    {
      activate: activatedNumber === 3,
      component: (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Number04 fill={palette.primary.main} />
          <Typography>A brief note for handover is here!</Typography>
          {NextButton}
        </Stack>
      ),
      reference: ref4,
      tooltip: { position: 'left' },
    },
    {
      activate: activatedNumber === 4,
      component: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Number05 fill={palette.primary.main} />
          <Typography sx={{ display: 'flex' }}>
            You can select the time on the right and then enter the nursing
            record.
          </Typography>
          {NextButton}
        </Stack>
      ),
      reference: ref5,
      tooltip: { position: 'left' },
    },
    {
      activate: activatedNumber === 5,
      component: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Number05 fill={palette.primary.main} />
          <Typography sx={{ display: 'flex' }}>
            To edit/delete the nursing record you have
            <br />
            created, click the “···” button at the top right
          </Typography>
          {NextButton}
        </Stack>
      ),
      reference: ref6,
      tooltip: { position: 'right' },
    },
  ];

  const coach: ICoachProps = coachList[activatedNumber];
  /**************/

  /* 다시 보지 않기 체크박스 관련 */
  useEffect(() => {
    if (getCookie('no_intro') !== 'true') setOpen(true);
  }, []);
  /*************************/

  return (
    <>
      <Introduction open={open} setOpen={setOpen} setShow={setShow} />
      <Box display="flex" minWidth={1440}>
        <MenuDrawer
          name={`${student_name}\n ${title}`}
          college_name={college_name}
          college_ci={college_ci}
          menuDrawerWidth={menuDrawerWidth}
          setMenuDrawerWidth={setMenuDrawerWidth}
          coachRefA={ref2}
          coachRefB={ref3}
        />
        <DisplayInformation
          menuDrawerWidth={menuDrawerWidth}
          coachRefA={ref1}
          coachRefB={ref6}
        />
        <InputInformation coachRefA={ref4} coachRefB={ref5} />
      </Box>
      {show && <CoachMark {...coach} />}
    </>
  );
}

export default Main;
