export const initialNursingRecord = {
  nanda: {
    domain: '',
    class: '',
    diagnosis: '',
    collectingData: '',
    goal: '',
    plan: '',
    interventions: '',
    evaluation: '',
  },
  soapie: {
    subjective: '',
    objective: '',
    assessment: '',
    planning: '',
    interventions: '',
    evaluation: '',
  },
  focusDar: { focus: '', data: '', action: '', response: '' },
  narrativeRecord: { narrativeNote: '' },
  remarks: {
    assessment: '',
    diagnosisRelate: '',
    diagnosis: '',
    goal: '',
    plan: '',
    interventions: '',
    evaluation: '',
  },
};

export const initialECardex = {
  update_at: '',
  other_remarks: '',
  remark_data: [],
  medication_data: [],
  lab_data: [],
  imaging_test_data: [],
  concerns: '',
  plans: '',
  evaluation: '',
};

export const initialTakingOver = {
  update_at: '',
  loc: '',
  vital_sign: '',
  current_condition: '',
  rbfi: '',
  reasons: '',
  intervention: '',
  other: '',
};

export const initialHospitalizationSurvey = {
  offer: '',
  update_at: '',
  contact1: [{ contact: '', name: '', relation: '', etc: '' }],
  contact2: [{ contact: '', name: '', relation: '', etc: '' }],
  contact3: [{ contact: '', name: '', relation: '', etc: '' }],
  default_info: {
    BT: '',
    DBP: '',
    PR: '',
    RR: '',
    SBP: '',
    Sp02: '',
    date: '',
    height: '',
    hospitalization_path: { value: 1, input: '' },
    hospitalization_reason: '',
    hospitalization_way: 1,
    joo_ho_so: '',
    status: 1,
    status02: 1,

    weight: '',
  },
  disease_history: {
    allergy: { value: 1, input: '' },
    family_history: { value: 1, input: '' },
    food_allergy: { value: 1, input: '' },
    history: { value: 1, date: '', checked: [], input: '' },
    latest_medicine: { value: 1, input: '' },
    medicine_allergy: { value: 1, input: '' },
    operation_history: { value: 1, input: '' },
  },
  body_status: {
    breath: { value: 1, checked: [], input: '' },
    camouflage: { value: 1, checked: [], input: '' },
    cycle: { value: 1, checked: [], input: '' },
    fain: { checked: [] },
    fall: { checked: [] },
    medicine_history: { value: 1, input: '' },
    nerve: { value: 1, checked: [], input: '' },
    operation_history: { value: 1, input: '' },
    skin: { value: 1, checked: [], input: '' },
    yukchang: { checked: [] },
  },
  habit: {
    drink: { value: 1, checked: [], input: '', input2: '' },
    feces: { value: '', input: '' },
    feces_info: { checked: [] },
    nutrition: { weight: 1, appetite: 1 },
    obstetric: { G: '0', T: '0', P: '0', A: '0', L: '0' },
    sleep: 1,
    smoke: { value: 1, checked: [], input: '', input2: '' },
    urine: { value: '', input: '' },
    urine_info: { checked: [] },
  },
  functional_evaluation: {
    sit: 1,
    stand_up: 1,
    walk: 1,
    wheel_chair: 1,
  },
  social_history: {
    job: '',
    language: '한국어',
    marry: 1,
    religion: { value: 1, input: '' },
  },
  economy_history: {
    counseling: 1,
    mind_status: { checked: [] },
  },
  education: {
    education_contents: { checked: [], input: '' },
    education_way: 1,
  },
  out_hospital_plan: {
    destination: { value: 1, input: '' },
    guardian: { value: 1, input: '' },
    life: 1,
  },
};

export const initialOutHospitalSurvey = {
  date: '',
  update_at: '',
  default_info: {
    destination: { value: 1, input: '' },
    out_hospital_way: { value: 1, input: '' },
    guardians: { value: 1, input: '' },
    food: { value: 1, input: '' },
    shower: { value: 1, input: '' },
    activity: 1,
  },
  out_hospital_medicines: [
    { amount: '', count: '', days: '', how_to: '', name: '' },
    { amount: '', count: '', days: '', how_to: '', name: '' },
    { amount: '', count: '', days: '', how_to: '', name: '' },
    { amount: '', count: '', days: '', how_to: '', name: '' },
    { amount: '', count: '', days: '', how_to: '', name: '' },
  ],
  out_patients: [
    { call_number: '', date: '', department: '', doctor: '', location: '' },
    { call_number: '', date: '', department: '', doctor: '', location: '' },
  ],
  check_reservations: [
    { call_number: '', date: '', destination: '', name: '', warning: '' },
    { call_number: '', date: '', destination: '', name: '', warning: '' },
  ],
  education: '',
};

export const initialMedicationSurvey = {
  update_at: '',
  medication_surveys: [],
};

export const initialRadiology = {
  radiologies: [],
};

export const initialPathology = {
  pathologies: [],
};

export const initialClinicalObservation = {
  update_at: '',
  survey_uuid: '',
  vital_sign: [],
  io_check: [],
};

export const initialClinicalObservationICU = {
  update_at: '',
  vital_sign: {
    vital00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
      no04: '',
      no05: '',
      no06: '',
      no07: '',
      no08: '',
      no09: '',
    },
    vital01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
      no14: '',
      no15: '',
      no16: '',
      no17: '',
      no18: '',
      no19: '',
    },
    vital02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
      no24: '',
      no25: '',
      no26: '',
      no27: '',
      no28: '',
      no29: '',
    },
    vital03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
      no34: '',
      no35: '',
      no36: '',
      no37: '',
      no38: '',
      no39: '',
    },
    vital04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
      no44: '',
      no45: '',
      no46: '',
      no47: '',
      no48: '',
      no49: '',
    },
    vital05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
      no54: '',
      no55: '',
      no56: '',
      no57: '',
      no58: '',
      no59: '',
    },
    vital06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
      no64: '',
      no65: '',
      no66: '',
      no67: '',
      no68: '',
      no69: '',
    },
    vital07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
      no74: '',
      no75: '',
      no76: '',
      no77: '',
      no78: '',
      no79: '',
    },
    vital08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
      no84: '',
      no85: '',
      no86: '',
      no87: '',
      no88: '',
      no89: '',
    },
    vital09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
      no94: '',
      no95: '',
      no96: '',
      no97: '',
      no98: '',
      no99: '',
    },
  },
  weight_height: {
    weight_height00: {
      no00: '',
      no01: '',
    },
    weight_height01: {
      no10: '',
      no11: '',
    },
    weight_height02: {
      no20: '',
      no21: '',
    },
    weight_height03: {
      no30: '',
      no31: '',
    },
    weight_height04: {
      no40: '',
      no41: '',
    },
    weight_height05: {
      no50: '',
      no51: '',
    },
    weight_height06: {
      no60: '',
      no61: '',
    },
    weight_height07: {
      no70: '',
      no71: '',
    },
    weight_height08: {
      no80: '',
      no81: '',
    },
    weight_height09: {
      no90: '',
      no91: '',
    },
  },
  intake: {
    intake00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
    },
    intake01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
    },
    intake02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
    },
    intake03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
    },
    intake04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
    },
    intake05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
    },
    intake06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
    },
    intake07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
    },
    intake08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
    },
    intake09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
    },
  },
  output: {
    output00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
      no04: '',
    },
    output01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
      no14: '',
    },
    output02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
      no24: '',
    },
    output03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
      no34: '',
    },
    output04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
      no44: '',
    },
    output05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
      no54: '',
    },
    output06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
      no64: '',
    },
    output07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
      no74: '',
    },
    output08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
      no84: '',
    },
    output09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
      no94: '',
    },
  },
  position_change: {
    position_change00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
    },
    position_change01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
    },
    position_change02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
    },
    position_change03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
    },
    position_change04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
    },
    position_change05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
    },
    position_change06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
    },
    position_change07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
    },
    position_change08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
    },
    position_change09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
    },
  },
  restraint: {
    restraint00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
      no04: '',
      no05: '',
      no06: '',
      no07: '',
      no08: '',
    },
    restraint01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
      no14: '',
      no15: '',
      no16: '',
      no17: '',
      no18: '',
    },
    restraint02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
      no24: '',
      no25: '',
      no26: '',
      no27: '',
      no28: '',
    },
    restraint03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
      no34: '',
      no35: '',
      no36: '',
      no37: '',
      no38: '',
    },
    restraint04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
      no44: '',
      no45: '',
      no46: '',
      no47: '',
      no48: '',
    },
    restraint05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
      no54: '',
      no55: '',
      no56: '',
      no57: '',
      no58: '',
    },
    restraint06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
      no64: '',
      no65: '',
      no66: '',
      no67: '',
      no68: '',
    },
    restraint07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
      no74: '',
      no75: '',
      no76: '',
      no77: '',
      no78: '',
    },
    restraint08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
      no84: '',
      no85: '',
      no86: '',
      no87: '',
      no88: '',
    },
    restraint09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
      no94: '',
      no95: '',
      no96: '',
      no97: '',
      no98: '',
    },
  },
  ipc: {
    ipc00: '',
    ipc01: '',
    ipc02: '',
    ipc03: '',
    ipc04: '',
    ipc05: '',
    ipc06: '',
    ipc07: '',
    ipc08: '',
    ipc09: '',
  },
  vent: {
    vent00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
      no04: '',
      no05: '',
      no06: '',
      no07: '',
      no08: '',
      no09: '',
      no010: '',
      no011: '',
    },
    vent01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
      no14: '',
      no15: '',
      no16: '',
      no17: '',
      no18: '',
      no19: '',
      no110: '',
      no111: '',
    },
    vent02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
      no24: '',
      no25: '',
      no26: '',
      no27: '',
      no28: '',
      no29: '',
      no210: '',
      no211: '',
    },
    vent03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
      no34: '',
      no35: '',
      no36: '',
      no37: '',
      no38: '',
      no39: '',
      no310: '',
      no311: '',
    },
    vent04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
      no44: '',
      no45: '',
      no46: '',
      no47: '',
      no48: '',
      no49: '',
      no410: '',
      no411: '',
    },
    vent05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
      no54: '',
      no55: '',
      no56: '',
      no57: '',
      no58: '',
      no59: '',
      no510: '',
      no511: '',
    },
    vent06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
      no64: '',
      no65: '',
      no66: '',
      no67: '',
      no68: '',
      no69: '',
      no610: '',
      no611: '',
    },
    vent07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
      no74: '',
      no75: '',
      no76: '',
      no77: '',
      no78: '',
      no79: '',
      no710: '',
      no711: '',
    },
    vent08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
      no84: '',
      no85: '',
      no86: '',
      no87: '',
      no88: '',
      no89: '',
      no810: '',
      no811: '',
    },
    vent09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
      no94: '',
      no95: '',
      no96: '',
      no97: '',
      no98: '',
      no99: '',
      no910: '',
      no911: '',
    },
  },
  crrt: {
    crrt00: {
      no00: '',
      no01: '',
      no02: '',
      no03: '',
      no04: '',
      no05: '',
      no06: '',
      no07: '',
      no08: '',
      no09: '',
      no010: '',
      no011: '',
      no012: '',
      no013: '',
      no014: '',
    },
    crrt01: {
      no10: '',
      no11: '',
      no12: '',
      no13: '',
      no14: '',
      no15: '',
      no16: '',
      no17: '',
      no18: '',
      no19: '',
      no110: '',
      no111: '',
      no112: '',
      no113: '',
      no114: '',
    },
    crrt02: {
      no20: '',
      no21: '',
      no22: '',
      no23: '',
      no24: '',
      no25: '',
      no26: '',
      no27: '',
      no28: '',
      no29: '',
      no210: '',
      no211: '',
      no212: '',
      no213: '',
      no214: '',
    },
    crrt03: {
      no30: '',
      no31: '',
      no32: '',
      no33: '',
      no34: '',
      no35: '',
      no36: '',
      no37: '',
      no38: '',
      no39: '',
      no310: '',
      no311: '',
      no312: '',
      no313: '',
      no314: '',
    },
    crrt04: {
      no40: '',
      no41: '',
      no42: '',
      no43: '',
      no44: '',
      no45: '',
      no46: '',
      no47: '',
      no48: '',
      no49: '',
      no410: '',
      no411: '',
      no412: '',
      no413: '',
      no414: '',
    },
    crrt05: {
      no50: '',
      no51: '',
      no52: '',
      no53: '',
      no54: '',
      no55: '',
      no56: '',
      no57: '',
      no58: '',
      no59: '',
      no510: '',
      no511: '',
      no512: '',
      no513: '',
      no514: '',
    },
    crrt06: {
      no60: '',
      no61: '',
      no62: '',
      no63: '',
      no64: '',
      no65: '',
      no66: '',
      no67: '',
      no68: '',
      no69: '',
      no610: '',
      no611: '',
      no612: '',
      no613: '',
      no614: '',
    },
    crrt07: {
      no70: '',
      no71: '',
      no72: '',
      no73: '',
      no74: '',
      no75: '',
      no76: '',
      no77: '',
      no78: '',
      no79: '',
      no710: '',
      no711: '',
      no712: '',
      no713: '',
      no714: '',
    },
    crrt08: {
      no80: '',
      no81: '',
      no82: '',
      no83: '',
      no84: '',
      no85: '',
      no86: '',
      no87: '',
      no88: '',
      no89: '',
      no810: '',
      no811: '',
      no812: '',
      no813: '',
      no814: '',
    },
    crrt09: {
      no90: '',
      no91: '',
      no92: '',
      no93: '',
      no94: '',
      no95: '',
      no96: '',
      no97: '',
      no98: '',
      no99: '',
      no910: '',
      no911: '',
      no912: '',
      no913: '',
      no914: '',
    },
  },
};

export const initialGlucose = {
  update_at: '',
  blood_sugar_log: [],
  prescription: [],
};

export const initialIntakeOutput = {
  update_at: '',
  intake_output_survey: [],
};

export const initialBedScore = {
  date: '',
  update_at: '',
  contents: {
    sensoryPerception: '',
    humidity: '',
    activity: '',
    mobility: '',
    nutrition: '',
    frictionAndDissolutionForce: '',
  },
};

export const initialNeeds = {
  date: '',
  update_at: '',
  body_status: {
    takeOffClothes: '',
    eat: '',
    sitUp: '',
    toilet: '',
    washFace: '',
    bath: '',
    transfer: '',
    controlStool: '',
    brushTeeth: '',
    changePosition: '',
    getOutRoom: '',
    controlUrine: '',
  },
  disease_status: {
    chronic: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      cancer: '',
    },
    circulatory: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      etc: '',
    },
    nervous: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      etc: '',
    },
    musculoskeletal: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      etc: '',
    },
    mentalAndBehavioral: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      etc: '',
    },
    respiratory: {
      checked1: false,
      checked2: false,
      checked5: false,
      etc: '',
    },
    renal: {
      checked1: false,
      checked5: false,
      etc: '',
    },
    other: {
      checked1: false,
      checked5: false,
      allergy: '',
      etc: '',
    },
  },
  reason1: '',
  reason2: '',
};

export const initialFall = {
  date: '',
  update_at: '',
  contents: {
    experience: '',
    diagnosis: '',
    walkingAids: '',
    intravenousLine: '',
    gait: '',
    consciousness: '',
  },
};

export const initialPediatric_fall = {
  date: '',
  update_at: '',
  contents: {
    age: '',
    diagnosis: '',
    sex: '',
    cognitive_disorder: '',
    environmental_factors: '',
    medication_use: '',
    surgical_sedative_anesthetic_factors: '',
  },
};

export const initialGCS = {
  date: '',
  update_at: '',
  eye_opening: '',
  verbal_response: '',
  motor_response: '',
};

export const initialPediatric_GCS = {
  date: '',
  update_at: '',
  eye_opening: '',
  verbal_response: '',
  motor_response: '',
};

export const initialFourScore = {
  date: '',
  update_at: '',
  eye_opening: '',
  brainstem_reflexes: '',
  motor_response: '',
  respiration: '',
};

export const initialSafety = {
  update_at: '',
  accident_consequences_details: {
    accidence_date: '',
    discovery_date: '',
    discovery_place: '',
    discovery_place_etc: '',
    accident_type: '',
    accident_classification: '',
    accident_classification_etc: '',
  },
  event_classification: '',
  falling_type: {
    consciousness_level: '',
    activity_status: '',
    assisting_devices: '',
    place_falling_accident: '',
    place_falling_accident_etc: '',
    patient_risk_factors: '',
    patient_risk_factors_two: '',
    score: 0,
    date: '',
    fall_type: '',
    risk_factor: '',
    risk_factor_etc: '',
  },
  medication_type: {
    prescription_error: '',
    prescription_error_etc: '',
    drug_preparation_error: '',
    drug_preparation_error_etc: '',
    confirm_error: '',
    interpretation_error: '',
  },
  other_type: '',
  other_type_etc: '',
  accident_detail: '',
  accident_handling: '',
  accident_result: '',
};

export const initialNRS = {
  update_at: '',
  nrs_survey: [],
};

export const initialFLACC = {
  update_at: '',
  flacc_survey: [],
};

export const initialCNPS = {
  update_at: '',
  face: '',
  activity: '',
  respiratory: '',
  vocalization: '',
  sum: '',
};

export const initialFFI = {
  update_at: '',
  ffi01: 0,
  ffi02: 0,
  ffi03: 0,
  ffi04: 0,
  ffi05: 0,
  ffi06: 0,
  ffi07: 0,
  ffi08: 0,

  ffi09: 0,
  ffi10: 0,
  ffi11: 0,
  ffi12: 0,
  ffi13: 0,
  ffi14: 0,
  ffi15: 0,
  ffi16: 0,
  ffi17: 0,
  ffi18: 0,

  ffi19: 0,
  ffi20: 0,
  ffi21: 0,
  ffi22: 0,
  ffi23: 0,
};

export const initialKOOS = {
  update_at: '',
  spt01: '',
  spt02: '',
  spt03: '',
  spt04: '',
  spt05: '',

  stf01: '',
  stf02: '',

  pain01: '',
  pain02: '',
  pain03: '',
  pain04: '',
  pain05: '',
  pain06: '',
  pain07: '',
  pain08: '',
  pain09: '',

  daily01: '',
  daily02: '',
  daily03: '',
  daily04: '',
  daily05: '',
  daily06: '',
  daily07: '',
  daily08: '',
  daily09: '',
  daily10: '',
  daily11: '',
  daily12: '',
  daily13: '',
  daily14: '',
  daily15: '',
  daily16: '',
  daily17: '',

  actv01: '',
  actv02: '',
  actv03: '',
  actv04: '',
  actv05: '',

  qol01: '',
  qol02: '',
  qol03: '',
  qol04: '',
};

export const initialLEFS = {
  update_at: '',
  lefs01: '',
  lefs02: '',
  lefs03: '',
  lefs04: '',
  lefs05: '',
  lefs06: '',
  lefs07: '',
  lefs08: '',
  lefs09: '',
  lefs10: '',
  lefs11: '',
  lefs12: '',
  lefs13: '',
  lefs14: '',
  lefs15: '',
  lefs16: '',
  lefs17: '',
  lefs18: '',
  lefs19: '',
  lefs20: '',
};

export const initialNDI = {
  update_at: '',
  ndi01: '',
  ndi02: '',
  ndi03: '',
  ndi04: '',
  ndi05: '',
  ndi06: '',
  ndi07: '',
  ndi08: '',
  ndi09: '',
  ndi10: '',
};

export const initialSTarTBack = {
  update_at: '',
  sb01: '',
  sb02: '',
  sb03: '',
  sb04: '',
  sb05: '',
  sb06: '',
  sb07: '',
  sb08: '',
  sb09: '',
};

export const initialMentalNursing = {
  update_at: '',
  mental_survey: [],
};

export const initialBDI = {
  update_at: '',
  content: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  sum: 0,
};

export const initialBAI = {
  update_at: '',
  content: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  sum: 0,
};

export const initialMMSE = {
  update_at: '',
  score: [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ],
  sum: 0,
};

export const initialOperation = {
  update_at: '',
  surgery_information: {
    operating_surgeon: '',
    assistant: '',
    scrubbing_nurse: '',
    circulating_nurse: '',
  },
  operation_information: {
    operating_department: '',
    operating_date: '',
    operating_time: '',
    asa_class: '',
    main_operation_name: '',
    minor_operation_name: '',
    past_history: '',
    allergy: '',
    npo_status: '',
    prophylactic_antibiotics: '',
    position: '',
    position_etc: '',
    preoperative_xray: true,
    preoperative_ekg: true,
  },
  surgery_details: {
    content: '',
    arrival_time: '',
    anesthesia_start_time: '',
    surgery_start_time: '',
    surgery_end_time: '',
    anesthesia_end_time: '',
    discharge_time: '',
    anesthetic_method: '',
    anesthetic_method_etc: '',
    anesthesiologist: '',
  },
};

export const initialAnesthesia = {
  update_at: '',
  surgery_information: {
    operating_surgeon: '',
    assistant: '',
    scrubbing_nurse: '',
    circulating_nurse: '',
  },
  operation_information: {
    operating_department: '',
    operating_date: '',
    operating_time: '',
    operation_name: '',
    npo_status: '',
    position: '',
    position_etc: '',
    past_history_and_allergy: '',
    preoperative_xray: false,
    preoperative_ekg: false,
    emergency_status: '',
    asa_class: '',
    prophylactic_antibiotics: '',
    prophylactic_method: '',
    prophylactic_method_etc: '',
  },
  prescription_record: [],
  patient_status_record: {
    infusion_amount: '',
    transfusion_amount: '',
    intake_etc: '',
    urine_amount: '',
    blood_clot_amount: '',
    output_etc: '',
  },
  patient_status_list_record: [],
};

export const initialTransfusion = {
  update_at: '',
  blood_number: '',
  blood_name: '',
  volume: '',
  arrival_time: '',
  blood_transfusion_arrival: '',
  transfusion_check1: '',
  transfusion_check2: '',
  transfusion_start_time: '',
  practitioner_start: '',
  transfusion_end_time: '',
  practitioner_end: '',
  nursing_implementation: '',
  transfusion_record: [],
};

export const initialIntubation = {
  update_at: '',
  cline_information: [],
  aline_information: [],
  foley_information: [],
  avf_information: [],
  hd_information: [],
};

export const initialCPR = {
  update_at: '',
  find_place: '',
  cpr_place: '',
  cpr_leader: '',
  writer: '',
  find_date: '',
  find_time: '',
  terminate_reason: '',
  clinical_observation: {
    bp0: '',
    hr0: '',
    rr0: '',
    bt0: '',
    spo20: '',
    consciousness0: '',
    pupil_size0: '',
    pupil_reflex0: '',
    cardio_ryt0: '',
    bp1: '',
    hr1: '',
    rr1: '',
    bt1: '',
    spo21: '',
    consciousness1: '',
    pupil_size1: '',
    pupil_reflex1: '',
    cardio_ryt1: '',
    bp2: '',
    hr2: '',
    rr2: '',
    bt2: '',
    spo22: '',
    consciousness2: '',
    pupil_size2: '',
    pupil_reflex2: '',
    cardio_ryt2: '',
    bp3: '',
    hr3: '',
    rr3: '',
    bt3: '',
    spo23: '',
    consciousness3: '',
    pupil_size3: '',
    pupil_reflex3: '',
    cardio_ryt3: '',
    bp4: '',
    hr4: '',
    rr4: '',
    bt4: '',
    spo24: '',
    consciousness4: '',
    pupil_size4: '',
    pupil_reflex4: '',
    cardio_ryt4: '',
    bp5: '',
    hr5: '',
    rr5: '',
    bt5: '',
    spo25: '',
    consciousness5: '',
    pupil_size5: '',
    pupil_reflex5: '',
    cardio_ryt5: '',
    bp6: '',
    hr6: '',
    rr6: '',
    bt6: '',
    spo26: '',
    consciousness6: '',
    pupil_size6: '',
    pupil_reflex6: '',
    cardio_ryt6: '',
    bp7: '',
    hr7: '',
    rr7: '',
    bt7: '',
    spo27: '',
    consciousness7: '',
    pupil_size7: '',
    pupil_reflex7: '',
    cardio_ryt7: '',
    bp8: '',
    hr8: '',
    rr8: '',
    bt8: '',
    spo28: '',
    consciousness8: '',
    pupil_size8: '',
    pupil_reflex8: '',
    cardio_ryt8: '',
    bp9: '',
    hr9: '',
    rr9: '',
    bt9: '',
    spo29: '',
    consciousness9: '',
    pupil_size9: '',
    pupil_reflex9: '',
    cardio_ryt9: '',
    bp10: '',
    hr10: '',
    rr10: '',
    bt10: '',
    spo210: '',
    consciousness10: '',
    pupil_size10: '',
    pupil_reflex10: '',
    cardio_ryt10: '',
    bp11: '',
    hr11: '',
    rr11: '',
    bt11: '',
    spo211: '',
    consciousness11: '',
    pupil_size11: '',
    pupil_reflex11: '',
    cardio_ryt11: '',
    bp12: '',
    hr12: '',
    rr12: '',
    bt12: '',
    spo212: '',
    consciousness12: '',
    pupil_size12: '',
    pupil_reflex12: '',
    cardio_ryt12: '',
    bp13: '',
    hr13: '',
    rr13: '',
    bt13: '',
    spo213: '',
    consciousness13: '',
    pupil_size13: '',
    pupil_reflex13: '',
    cardio_ryt13: '',
    bp14: '',
    hr14: '',
    rr14: '',
    bt14: '',
    spo214: '',
    consciousness14: '',
    pupil_size14: '',
    pupil_reflex14: '',
    cardio_ryt14: '',
    bp15: '',
    hr15: '',
    rr15: '',
    bt15: '',
    spo215: '',
    consciousness15: '',
    pupil_size15: '',
    pupil_reflex15: '',
    cardio_ryt15: '',
    bp16: '',
    hr16: '',
    rr16: '',
    bt16: '',
    spo216: '',
    consciousness16: '',
    pupil_size16: '',
    pupil_reflex16: '',
    cardio_ryt16: '',
    bp17: '',
    hr17: '',
    rr17: '',
    bt17: '',
    spo217: '',
    consciousness17: '',
    pupil_size17: '',
    pupil_reflex17: '',
    cardio_ryt17: '',
    bp18: '',
    hr18: '',
    rr18: '',
    bt18: '',
    spo218: '',
    consciousness18: '',
    pupil_size18: '',
    pupil_reflex18: '',
    cardio_ryt18: '',
    bp19: '',
    hr19: '',
    rr19: '',
    bt19: '',
    spo219: '',
    consciousness19: '',
    pupil_size19: '',
    pupil_reflex19: '',
    cardio_ryt19: '',
    bp20: '',
    hr20: '',
    rr20: '',
    bt20: '',
    spo220: '',
    consciousness20: '',
    pupil_size20: '',
    pupil_reflex20: '',
    cardio_ryt20: '',
    bp21: '',
    hr21: '',
    rr21: '',
    bt21: '',
    spo221: '',
    consciousness21: '',
    pupil_size21: '',
    pupil_reflex21: '',
    cardio_ryt21: '',
  },
  treatment: {
    chest_compression0: '',
    artificial_ventilation0: '',
    aed0: '',
    chest_compression1: '',
    artificial_ventilation1: '',
    aed1: '',
    chest_compression2: '',
    artificial_ventilation2: '',
    aed2: '',
    chest_compression3: '',
    artificial_ventilation3: '',
    aed3: '',
    chest_compression4: '',
    artificial_ventilation4: '',
    aed4: '',
    chest_compression5: '',
    artificial_ventilation5: '',
    aed5: '',
    chest_compression6: '',
    artificial_ventilation6: '',
    aed6: '',
    chest_compression7: '',
    artificial_ventilation7: '',
    aed7: '',
    chest_compression8: '',
    artificial_ventilation8: '',
    aed8: '',
    chest_compression9: '',
    artificial_ventilation9: '',
    aed9: '',
    chest_compression10: '',
    artificial_ventilation10: '',
    aed10: '',
    chest_compression11: '',
    artificial_ventilation11: '',
    aed11: '',
    chest_compression12: '',
    artificial_ventilation12: '',
    aed12: '',
    chest_compression13: '',
    artificial_ventilation13: '',
    aed13: '',
    chest_compression14: '',
    artificial_ventilation14: '',
    aed14: '',
    chest_compression15: '',
    artificial_ventilation15: '',
    aed15: '',
    chest_compression16: '',
    artificial_ventilation16: '',
    aed16: '',
    chest_compression17: '',
    artificial_ventilation17: '',
    aed17: '',
    chest_compression18: '',
    artificial_ventilation18: '',
    aed18: '',
    chest_compression19: '',
    artificial_ventilation19: '',
    aed19: '',
    chest_compression20: '',
    artificial_ventilation20: '',
    aed20: '',
    chest_compression21: '',
    artificial_ventilation21: '',
    aed21: '',
  },
  intubation: {
    id0: '',
    depth0: '',
    balloon0: '',
    operator0: '',
    practitioner0: '',
    id1: '',
    depth1: '',
    balloon1: '',
    operator1: '',
    practitioner1: '',
    id2: '',
    depth2: '',
    balloon2: '',
    operator2: '',
    practitioner2: '',
    id3: '',
    depth3: '',
    balloon3: '',
    operator3: '',
    practitioner3: '',
    id4: '',
    depth4: '',
    balloon4: '',
    operator4: '',
    practitioner4: '',
    id5: '',
    depth5: '',
    balloon5: '',
    operator5: '',
    practitioner5: '',
    id6: '',
    depth6: '',
    balloon6: '',
    operator6: '',
    practitioner6: '',
    id7: '',
    depth7: '',
    balloon7: '',
    operator7: '',
    practitioner7: '',
    id8: '',
    depth8: '',
    balloon8: '',
    operator8: '',
    practitioner8: '',
    id9: '',
    depth9: '',
    balloon9: '',
    operator9: '',
    practitioner9: '',
    id10: '',
    depth10: '',
    balloon10: '',
    operator10: '',
    practitioner10: '',
    id11: '',
    depth11: '',
    balloon11: '',
    operator11: '',
    practitioner11: '',
    id12: '',
    depth12: '',
    balloon12: '',
    operator12: '',
    practitioner12: '',
    id13: '',
    depth13: '',
    balloon13: '',
    operator13: '',
    practitioner13: '',
    id14: '',
    depth14: '',
    balloon14: '',
    operator14: '',
    practitioner14: '',
    id15: '',
    depth15: '',
    balloon15: '',
    operator15: '',
    practitioner15: '',
    id16: '',
    depth16: '',
    balloon16: '',
    operator16: '',
    practitioner16: '',
    id17: '',
    depth17: '',
    balloon17: '',
    operator17: '',
    practitioner17: '',
    id18: '',
    depth18: '',
    balloon18: '',
    operator18: '',
    practitioner18: '',
    id19: '',
    depth19: '',
    balloon19: '',
    operator19: '',
    practitioner19: '',
    id20: '',
    depth20: '',
    balloon20: '',
    operator20: '',
    practitioner20: '',
    id21: '',
    depth21: '',
    balloon21: '',
    operator21: '',
    practitioner21: '',
  },
  medication: {
    no00_1: '',
    no00_2: '',
    no00_3: '',
    no00_4: '',
    no00_5: '',
    no00_6: '',
    no00_7: '',
    no00_12: '',
    no00_13: '',
    no00_14: '',
    no00_15: '',
    no00_16: '',
    no00_17: '',
    no00_18: '',
    no0_1: '',
    no0_2: '',
    no0_3: '',
    no0_4: '',
    no0_5: '',
    no0_6: '',
    no0_7: '',
    no1_1: '',
    no1_2: '',
    no1_3: '',
    no1_4: '',
    no1_5: '',
    no1_6: '',
    no1_7: '',
    no2_1: '',
    no2_2: '',
    no2_3: '',
    no2_4: '',
    no2_5: '',
    no2_6: '',
    no2_7: '',
    no3_1: '',
    no3_2: '',
    no3_3: '',
    no3_4: '',
    no3_5: '',
    no3_6: '',
    no3_7: '',
    no4_1: '',
    no4_2: '',
    no4_3: '',
    no4_4: '',
    no4_5: '',
    no4_6: '',
    no4_7: '',
    no5_1: '',
    no5_2: '',
    no5_3: '',
    no5_4: '',
    no5_5: '',
    no5_6: '',
    no5_7: '',
    no6_1: '',
    no6_2: '',
    no6_3: '',
    no6_4: '',
    no6_5: '',
    no6_6: '',
    no6_7: '',
    no7_1: '',
    no7_2: '',
    no7_3: '',
    no7_4: '',
    no7_5: '',
    no7_6: '',
    no7_7: '',
    no8_1: '',
    no8_2: '',
    no8_3: '',
    no8_4: '',
    no8_5: '',
    no8_6: '',
    no8_7: '',
    no9_1: '',
    no9_2: '',
    no9_3: '',
    no9_4: '',
    no9_5: '',
    no9_6: '',
    no9_7: '',
    no10_1: '',
    no10_2: '',
    no10_3: '',
    no10_4: '',
    no10_5: '',
    no10_6: '',
    no10_7: '',
    no10__1: '',
    no10__2: '',
    no10__3: '',
    no10__4: '',
    no10__5: '',
    no10__6: '',
    no10__7: '',
    no11_1: '',
    no11_2: '',
    no11_3: '',
    no11_4: '',
    no11_5: '',
    no11_6: '',
    no11_7: '',
    no12_1: '',
    no12_2: '',
    no12_3: '',
    no12_4: '',
    no12_5: '',
    no12_6: '',
    no12_7: '',
    no13_1: '',
    no13_2: '',
    no13_3: '',
    no13_4: '',
    no13_5: '',
    no13_6: '',
    no13_7: '',
    no14_1: '',
    no14_2: '',
    no14_3: '',
    no14_4: '',
    no14_5: '',
    no14_6: '',
    no14_7: '',
    no15_1: '',
    no15_2: '',
    no15_3: '',
    no15_4: '',
    no15_5: '',
    no15_6: '',
    no15_7: '',
    no16_1: '',
    no16_2: '',
    no16_3: '',
    no16_4: '',
    no16_5: '',
    no16_6: '',
    no16_7: '',
    no17_1: '',
    no17_2: '',
    no17_3: '',
    no17_4: '',
    no17_5: '',
    no17_6: '',
    no17_7: '',
    no18_1: '',
    no18_2: '',
    no18_3: '',
    no18_4: '',
    no18_5: '',
    no18_6: '',
    no18_7: '',
    no19_1: '',
    no19_2: '',
    no19_3: '',
    no19_4: '',
    no19_5: '',
    no19_6: '',
    no19_7: '',
    no20_1: '',
    no20_2: '',
    no20_3: '',
    no20_4: '',
    no20_5: '',
    no20_6: '',
    no20_7: '',
    no21_1: '',
    no21_2: '',
    no21_3: '',
    no21_4: '',
    no21_5: '',
    no21_6: '',
    no21_7: '',
  },
  test: {
    abga0: false,
    chest0: false,
    lab0: false,
    abga1: false,
    chest1: false,
    lab1: false,
    abga2: false,
    chest2: false,
    lab2: false,
    abga3: false,
    chest3: false,
    lab3: false,
    abga4: false,
    chest4: false,
    lab4: false,
    abga5: false,
    chest5: false,
    lab5: false,
    abga6: false,
    chest6: false,
    lab6: false,
    abga7: false,
    chest7: false,
    lab7: false,
    abga8: false,
    chest8: false,
    lab8: false,
    abga9: false,
    chest9: false,
    lab9: false,
    abga10: false,
    chest10: false,
    lab10: false,
    abga11: false,
    chest11: false,
    lab11: false,
    abga12: false,
    chest12: false,
    lab12: false,
    abga13: false,
    chest13: false,
    lab13: false,
    abga14: false,
    chest14: false,
    lab14: false,
    abga15: false,
    chest15: false,
    lab15: false,
    abga16: false,
    chest16: false,
    lab16: false,
    abga17: false,
    chest17: false,
    lab17: false,
    abga18: false,
    chest18: false,
    lab18: false,
    abga19: false,
    chest19: false,
    lab19: false,
    abga20: false,
    chest20: false,
    lab20: false,
    abga21: false,
    chest21: false,
    lab21: false,
  },
};

export const initialDialysis = {
  update_at: '',
  date: '',
  time: '',
  visiting_route: '',
  visiting_route_etc: '',
  dialysis_machine: '',
  dialyzer: '',
  dialysate: '',
  vascular_access: '',
  vascular_access_etc: '',
  starting_nurse: '',
  ending_nurse: '',
  pre_previous_weight: '',
  pre_today_weight: '',
  pre_weight_change: '',
  post_previous_weight: '',
  post_today_weight: '',
  post_weight_change: '',
  dialysis_db: [],
  crrt: [],
  additional_information: '',
};

export const initialEmergency = {
  update_at: '',
  emergency_information: {
    accident_date: '',
    accident_time: '',
    visit_method: '',
    memo: '',
    arrival_time: '',
  },
  emergency_patient_information: {
    symptom: '',
    etc_symptom: '',
    damage: '',
    intentional: '',
    medical_conditions: '',
    history: '',
    allergy: '',
    medication: '',
    consciousness: '',
    gcs: '',
    motor: '',
    pupil: '',
    nrs: '',
    pqrst: '',
    ktas: '',
    expected_diagnosis: '',
  },
  patient_status_record: {
    infusion_amount: '',
    transfusion_amount: '',
    intake_etc: '',
    urine_amount: '',
    blood_clot_amount: '',
    output_etc: '',
  },
  patient_status_list_record: [],
  emergency_care_result: {
    result: { value: 1, input: '' },
    status: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      placeholder: '',
    },
  },
};

export const initialChildbirth = {
  update_at: '',
  child_birth_information: {
    date: '',
    time: '',
    type: '',
  },
  newborn_condition: {
    gender: -1,
    weight: '',
    apgar_score1m: '',
    apgar_score5m: '',
    oxygen_intake: -1,
    first_urine: -1,
    placenta_discharge: -1,
    fetal_staining: -1,
    nuchal_cord: -1,
    nuchal_cord_content: '',
    resuscitation: -1,
    resuscitation_content: '',
    specifications: '',
  },
  apgar: {
    appearance: -1,
    pulse: -1,
    grimace: -1,
    activity: -1,
    respiration: -1,
  },
  placenta_removal: {
    time: '',
    methods: -1,
    curettage: -1,
  },
  maternal_condition: {
    episiotomy: -1,
    episiotomy_content: '',
    perineal_laceration: -1,
    perineal_laceration_content: '',
    uterus_contraction: '',
  },
  nursing_records: [],
};

export const initialHomeCare = {
  update_at: '',
  basic_information: {
    occupation: '',
    insurance_type: '',
    residence: '',
    residence_etc: '',
    religion: '',
    nursing_provider: '',
    sanitary: '',
    sanitary_notes: '',
    marital_status: '',
    past_history: '',
    safety: '',
    safety_notes: '',
  },
  request_status: [],
  inspection_findings: [],
  medication_records: [],
  need_service: '',
};

export const initialDietNutrition = {
  update_at: '',
  birth: '',
  classification: 1,

  //  조식
  break_fast: {
    calorie: 0,
    guardian_meal: false,
    default_diet: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
    },
    treatment: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      checked9: false,
      checked10: false,
      checked11: false,
      checked12: false,
      checked13: false,
      checked14: false,
      checked15: false,
      checked16: false,
      checked17: false,
      checked18: false,
      checked19: false,
      checked20: false,
      checked21: false,
      checked22: false,
      checked23: false,
      checked24: false,
      checked25: false,
      checked26: false,
      checked27: false,
      checked28: false,
      checked29: false,
      checked30: false,
      checked31: false,
      checked32: false,
      checked33: false,
      checked34: false,
      checked35: false,
      checked36: false,
      checked37: false,
      checked38: false,
      checked39: false,
      checked40: false,
      checked41: false,
      checked42: false,
      checked43: false,
      checked44: false,
    },
    special: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      checked9: false,
      checked10: false,
      checked11: false,
      checked12: false,
      checked13: false,
      checked14: false,
      checked15: false,
      checked16: false,
      checked17: false,
      checked18: false,
      checked19: false,
      checked20: false,
      checked21: false,
      checked22: false,
      checked23: false,
      checked24: false,
    },
  },
  lunch: {
    calorie: 0,
    guardian_meal: false,
    default_diet: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
    },
    treatment: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      checked9: false,
      checked10: false,
      checked11: false,
      checked12: false,
      checked13: false,
      checked14: false,
      checked15: false,
      checked16: false,
      checked17: false,
      checked18: false,
      checked19: false,
      checked20: false,
      checked21: false,
      checked22: false,
      checked23: false,
      checked24: false,
      checked25: false,
      checked26: false,
      checked27: false,
      checked28: false,
      checked29: false,
      checked30: false,
      checked31: false,
      checked32: false,
      checked33: false,
      checked34: false,
      checked35: false,
      checked36: false,
      checked37: false,
      checked38: false,
      checked39: false,
      checked40: false,
      checked41: false,
      checked42: false,
      checked43: false,
      checked44: false,
    },
    special: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      checked9: false,
      checked10: false,
      checked11: false,
      checked12: false,
      checked13: false,
      checked14: false,
      checked15: false,
      checked16: false,
      checked17: false,
      checked18: false,
      checked19: false,
      checked20: false,
      checked21: false,
      checked22: false,
      checked23: false,
      checked24: false,
    },
  },
  dinner: {
    calorie: 0,
    guardian_meal: false,
    default_diet: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
    },
    treatment: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      checked9: false,
      checked10: false,
      checked11: false,
      checked12: false,
      checked13: false,
      checked14: false,
      checked15: false,
      checked16: false,
      checked17: false,
      checked18: false,
      checked19: false,
      checked20: false,
      checked21: false,
      checked22: false,
      checked23: false,
      checked24: false,
      checked25: false,
      checked26: false,
      checked27: false,
      checked28: false,
      checked29: false,
      checked30: false,
      checked31: false,
      checked32: false,
      checked33: false,
      checked34: false,
      checked35: false,
      checked36: false,
      checked37: false,
      checked38: false,
      checked39: false,
      checked40: false,
      checked41: false,
      checked42: false,
      checked43: false,
      checked44: false,
    },
    special: {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked8: false,
      checked9: false,
      checked10: false,
      checked11: false,
      checked12: false,
      checked13: false,
      checked14: false,
      checked15: false,
      checked16: false,
      checked17: false,
      checked18: false,
      checked19: false,
      checked20: false,
      checked21: false,
      checked22: false,
      checked23: false,
      checked24: false,
    },
  },
};

export const initialFallPrevention = {
  update_at: '',
  fall_education: {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
    checked7: false,
    checked8: false,
  },
  signature: '',
  date: '',
  personnel_signature: '',
};

export const initialCRRT = {
  update_at: '',
  no1_1: '',
  no1_2: {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
    checked7: false,
    checked8: false,
    checked9: false,
    checked10: false,
  },
  no1_3: '',

  no2: '',
  no3_1: {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
  },

  date: '',
  relationship: '',
  name: '',
  applier_contact: '',
  sign: '',
};

// CVC => 즁심정맥삽관동의서
export const initialCentralVenous = {
  update_at: '',
  no1: {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
  },

  pt_bday: '',
  pt_contact: '',
  pt_name: '',
  pt_sign: '',

  nok_bday: '',
  nok_contact: '',
  nok_name: '',
  nok_sign: '',

  dr_name: '',
  dr_sign: '',
};

// ETI  => 기관내삽관동의서
export const initialIntubationAgree = {
  update_at: '',
  no4: '',

  date: '',
  relationship: '',
  name: '',
  sign: '',

  dr_name: '',
  dr_sign: '',
};

// Physical Restraint => 억제대 동의서
export const initialSuppressor = {
  update_at: '',
  no2_1: '',
  no2_2: '',
  no2_3: '',
  no2_4: '',
  no2_5: '',
  no2_dr_name: '',
  no2_dr_sign: '',

  no3_check_01: false,
  no3_check_02: false,
  no3_check_03: false,
  no3_check_04: false,

  no3_input_box: '',
  date: '',
  relationship: '',
  name: '',
  sign: '',
  dr_name: '',
  dr_sign: '',
  ns_name: '',
  ns_sign: '',
};

export const initialTransfusionAgree = {
  update_at: '',
  pt_bday: '',
  pt_contact: '',
  pt_name: '',
};

export const initialColonoscopy = {
  update_at: '',
  companion_name: '',
  companion_sig: '',
  dr_name: '',
  dr_sig: '',
};

export const initialTransfusionAgreement = {
  update_at: '',
  interpreter_check1: '',
  interpreter_check2: '',
  interpreter_check3: '',
  interpreter_check4: '',

  consent: {
    checked01: false,
    checked02: false,
    checked03: false,
    checked04: false,
  },

  patient_sig: '',
  relationship: '',
  date: '',
  dr_sig: '',
  dr_date: '',
};

export const initialNonSalary = {
  update_at: '',
  date: '',
  name: '',
  sig: '',
};

export const initialAdmissionCheckList = {
  update_at: '',
  education_list: {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
    checked7: false,
    checked8: false,
    checked9: false,
    checked10: false,
    checked11: false,
    checked12: false,
    checked13: false,
    checked14: false,
    checked15: false,
    checked16: false,
    checked17: false,
    checked18: false,
    checked19: false,
    checked20: false,
    checked21: false,
  },

  date: '',
  name: '',
  sig: '',
};

export const initialFallConfirm = {
  update_at: '',
  fall_education: {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
    checked7: false,
    checked8: false,
  },
  signature: '',
  date: '',
  personnel_signature: '',
};

export const initialDNR = {
  update_at: '',
  recorde_date: '',
  recorde_person_name: '',
  recorde_person_sig: '',

  register_date: '',
  register_person_name: '',
  register_person_sig: '',
};

export const initialCoreNursingSkillVideo = {
  update_at: '',
  files: [],
};

export const initialCoreNursingSkillVideoExemple = {
  update_at: '',
  files: [],
};

export const initialHospitalConfirm = {
  update_at: '',
  name: '',
  relationship: '',
  signature: '',
};
