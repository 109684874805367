import {
  getECardex,
  getTakingOver,
  getBedScore,
  getClinicObservation,
  getFall,
  getPediatric_fall,
  getGCS,
  getPediatric_GCS,
  getFourScore,
  getSafety,
  getHospitalization,
  getMedication,
  getNeeds,
  getOutHospital,
  getPathology,
  getRadiology,
  getNRS,
  getFLACC,
  getCNPS,
  getFFI,
  getLEFS,
  getSTarT,
  getKOOS,
  getNDI,
  getMentalNursing,
  getBDI,
  getBAI,
  getMMSE,
  getOperation,
  getAnesthesia,
  getTransfusion,
  getDialysis,
  getEmergency,
  getChildbirth,
  getHomeCare,
  getDietNutrition,
  getColonoscopy,
  getUpperEndoscopy,
  getNonSalary,
  getDNR,
  getTransfusionAgree,
  getIntakeOutput,
  getGlucose,
  getClinicObservationICU,
  getIntubation,
  getCPR,
  getDietList,
  getHospitalConfirm,
  getFallConfirm,
  getCRRT,
  getCentralVenous,
  getIntubationAgree,
  getSuppressor,
  getAdmCheckList,
} from 'apis/survey';
import useNotification from 'hooks/useNotification';
import { findKeyValueToObj, findKeyValueToObjNoParse } from 'utils/convert';

import {
  initialECardex,
  initialTakingOver,
  initialOutHospitalSurvey,
  initialHospitalizationSurvey,
  initialMedicationSurvey,
  initialRadiology,
  initialPathology,
  initialBedScore,
  initialFall,
  initialGCS,
  initialPediatric_GCS,
  initialFourScore,
  initialSafety,
  initialPediatric_fall,
  initialNeeds,
  initialClinicalObservation,
  initialGlucose,
  initialNRS,
  initialFLACC,
  initialCNPS,
  initialFFI,
  initialLEFS,
  initialSTarTBack,
  initialKOOS,
  initialNDI,
  initialMentalNursing,
  initialBDI,
  initialBAI,
  initialMMSE,
  initialOperation,
  initialAnesthesia,
  initialTransfusion,
  initialDialysis,
  initialEmergency,
  initialChildbirth,
  initialHomeCare,
  initialDietNutrition,
  initialFallPrevention,
  initialCoreNursingSkillVideo,
  initialCoreNursingSkillVideoExemple,
  initialColonoscopy,
  initialTransfusionAgreement,
  initialNonSalary,
  initialAdmissionCheckList,
  initialDNR,
  initialTransfusionAgree,
  initialIntakeOutput,
  initialClinicalObservationICU,
  initialIntubation,
  initialCPR,
  initialHospitalConfirm,
  initialFallConfirm,
  initialCRRT,
  initialCentralVenous,
  initialIntubationAgree,
  initialSuppressor,
} from '../initialStates';
import { MENU } from '../type';

interface Props {
  user_id: number;
  setDefaultValues: (v: any) => void;
}

const useDefaultValues = ({ setDefaultValues, user_id }: Props) => {
  const { onFail } = useNotification();

  const convertDataToStates = (data: any, states: any) => {
    const values = { ...states };
    const keys = Object.keys(states);

    for (let key of keys) {
      const getValue = data[key];

      if (typeof getValue !== 'object' || Array.isArray(getValue)) {
        values[key] = getValue;
      } else if (getValue) {
        try {
          values[key] = findKeyValueToObj(getValue, Object.keys(getValue));
        } catch {
          values[key] = findKeyValueToObjNoParse(
            getValue,
            Object.keys(getValue)
          );
        }
      }
    }

    setDefaultValues(values);
  };

  const onGetDefaultValues = (patient_id: number, type: string) => {
    switch (type) {
      case MENU.E_CARDEX:
        getECardex({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, ecardex_survey } = data;
            convertDataToStates(
              { update_at, ...ecardex_survey },
              initialECardex
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.TAKING_OVER:
        getTakingOver({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, take_over_survey } = data;
            convertDataToStates(
              { update_at, ...take_over_survey },
              initialTakingOver
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.HOSPITALIZATION:
        getHospitalization({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, hospitalization_survey } = data;
            convertDataToStates(
              { update_at, ...hospitalization_survey },
              initialHospitalizationSurvey
            );
          })

          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.OUT_HOSPITAL:
        getOutHospital({ user_id, patient_id })
          .then(({ data }) => {
            const { out_hospital_survey, update_at } = data;
            convertDataToStates(
              { update_at, ...out_hospital_survey },
              initialOutHospitalSurvey
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.MEDICATION:
        getMedication({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialMedicationSurvey);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.RADIOLOGY:
        getRadiology({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialRadiology);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.PATHOLOGY:
        getPathology({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialPathology);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CLINICAL_OBSERVATION:
        getClinicObservation({ user_id, patient_id })
          .then(({ data }) => {
            const { vital_sign, io_check } = data;
            const m_data = {
              ...data,
              vital_sign: JSON.parse(vital_sign),
              io_check: JSON.parse(io_check),
            };
            convertDataToStates(m_data, initialClinicalObservation);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CLINICAL_OBSERVATION_ICU:
        getClinicObservationICU({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, catholic_clinical_observation } = data;

            convertDataToStates(
              { update_at, ...catholic_clinical_observation },
              initialClinicalObservationICU
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CLINICAL_OBSERVATION:
        // getClinicObservation({ user_id, patient_id }).then(({ data }) => {
        //   const { vital_sign, io_check } = data;
        //   const m_data = {
        //     ...data,
        //     vital_sign: JSON.parse(vital_sign),
        //     io_check: JSON.parse(io_check),
        //   };
        //   convertDataToStates(m_data, initialClinicalObservation);
        // });
        convertDataToStates(
          { vital_sign: [], io_check: [] },
          initialClinicalObservation
        );
        break;
      case MENU.IORECORD:
        getIntakeOutput({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialIntakeOutput);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.GLUCOSE:
        getGlucose({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, blood_sugar_survey } = data;
            convertDataToStates(
              { update_at, ...blood_sugar_survey },
              initialGlucose
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.BEDSORES:
        getBedScore({ user_id, patient_id })
          .then(({ data }) => {
            const { contents } = data;
            const m_data = { ...data, contents: JSON.parse(contents) };
            convertDataToStates(m_data, initialBedScore);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.NEEDS:
        getNeeds({ user_id, patient_id })
          .then(({ data }) => {
            const { body_status, disease_status } = data;
            const m_data = {
              ...data,
              body_status: JSON.parse(body_status),
              disease_status: JSON.parse(disease_status),
            };
            convertDataToStates(m_data, initialNeeds);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.FALL:
        getFall({ user_id, patient_id })
          .then(({ data }) => {
            const { contents } = data;
            const m_data = { ...data, contents: JSON.parse(contents) };
            convertDataToStates(m_data, initialFall);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.FALLSCALE:
        getPediatric_fall({ user_id, patient_id })
          .then(({ data }) => {
            const { contents } = data;
            const m_data = { ...data, contents: JSON.parse(contents) };
            convertDataToStates(m_data, initialPediatric_fall);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.DIET_NUTRITION:
        getDietList({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, dietary_survey } = data;

            convertDataToStates(
              { update_at, ...dietary_survey },
              initialDietNutrition
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.GCS:
        getGCS({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, gcs_survey } = data;
            convertDataToStates({ update_at, ...gcs_survey }, initialGCS);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.Pediatric_GCS:
        getPediatric_GCS({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, pediatric_gcs_survey } = data;
            convertDataToStates(
              { update_at, ...pediatric_gcs_survey },
              initialPediatric_GCS
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.FourScore:
        getFourScore({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, four_score_survey } = data;
            convertDataToStates(
              { update_at, ...four_score_survey },
              initialFourScore
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.SAFETY:
        getSafety({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, safety_survey } = data;
            convertDataToStates(
              {
                update_at,
                accident_consequences_details:
                  safety_survey?.accident_consequences_details,
                event_classification: safety_survey?.event_classification,
                falling_type: {
                  ...safety_survey?.falling_type,
                  patient_risk_factors: safety_survey
                    ? JSON.parse(
                        safety_survey.falling_type?.patient_risk_factors
                      )
                    : [],
                  patient_risk_factors_two: safety_survey.falling_type
                    ?.patient_risk_factors_two
                    ? JSON.parse(
                        safety_survey.falling_type?.patient_risk_factors_two
                      )
                    : [],
                },
                medication_type: safety_survey?.medication_type,
                other_type: safety_survey?.other_type,
                other_type_etc: safety_survey?.other_type_etc,
                accident_detail: safety_survey?.accident_detail,
                accident_handling: safety_survey?.accident_handling,
                accident_result: safety_survey?.accident_result,
              },
              initialSafety
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.NRS:
        getNRS({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialNRS);
          })
          .catch(e => onFail('알 수 없는 오류가 발생했습니다', e));
        break;
      case MENU.FLACC:
        getFLACC({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialFLACC);
          })
          .catch(e => onFail('알 수 없는 오류가 발생했습니다', e));
        break;
      case MENU.CNPS:
        getCNPS({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, cnps_survey } = data;
            convertDataToStates({ update_at, ...cnps_survey }, initialCNPS);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.MENTAL_NURSING:
        getMentalNursing({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialMentalNursing);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.FFI:
        getFFI({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, ffi_survey } = data;
            convertDataToStates({ update_at, ...ffi_survey }, initialFFI);
          })
          .catch(e => onFail('알 수 없는 오류가 발생했습니다', e));
        break;
      case MENU.KOOS:
        getKOOS({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, contents } = data;
            convertDataToStates({ update_at, ...contents }, initialKOOS);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.LEFS:
        getLEFS({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, contents } = data;
            convertDataToStates({ update_at, ...contents }, initialLEFS);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.NDI:
        getNDI({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, contents } = data;
            convertDataToStates({ update_at, ...contents }, initialNDI);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.STarT_BacknScreening:
        getSTarT({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, contents } = data;
            convertDataToStates({ update_at, ...contents }, initialSTarTBack);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.BDI:
        getBDI({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, bdi_survey } = data;
            convertDataToStates({ update_at, ...bdi_survey }, initialBDI);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.BAI:
        getBAI({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, bai_survey } = data;
            convertDataToStates({ update_at, ...bai_survey }, initialBAI);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.MMSE:
        getMMSE({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialMMSE);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.OPERATION:
        getOperation({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, surgical_survey } = data;
            convertDataToStates(
              { update_at, ...surgical_survey },
              initialOperation
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.ANESTHESIA:
        getAnesthesia({ user_id, patient_id })
          .then(({ data }) => {
            convertDataToStates(data, initialAnesthesia);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.TRANSFUSION:
        getTransfusion({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, transfusion_survey } = data;
            convertDataToStates(
              {
                update_at,
                ...transfusion_survey?.transfusion_information,
                transfusion_record: transfusion_survey?.transfusion_record,
              },
              initialTransfusion
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.INTUBATION:
        getIntubation({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, line_survey } = data;
            convertDataToStates(
              {
                update_at,
                cline_information: line_survey?.cline_information,
                aline_information: line_survey?.aline_information,
                foley_information: line_survey?.foley_information,
                avf_information: line_survey?.avf_information,
                hd_information: line_survey?.hd_information,
              },
              initialIntubation
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CPR:
        getCPR({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, catholic_cpr_survey } = data;
            convertDataToStates(
              {
                update_at,
                find_place: catholic_cpr_survey?.find_place,
                cpr_place: catholic_cpr_survey?.cpr_place,
                cpr_leader: catholic_cpr_survey?.cpr_leader,
                writer: catholic_cpr_survey?.writer,
                find_date: catholic_cpr_survey?.find_date,
                find_time: catholic_cpr_survey?.find_time,
                terminate_reason: catholic_cpr_survey?.terminate_reason,
                clinical_observation: catholic_cpr_survey?.clinical_observation,
                treatment: catholic_cpr_survey?.treatment,
                intubation: catholic_cpr_survey?.intubation,
                medication: catholic_cpr_survey?.medication,
                test: catholic_cpr_survey?.test,
              },
              initialCPR
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.DIALYSIS:
        getDialysis({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, hemodialysis_survey } = data;
            convertDataToStates(
              {
                update_at,
                ...hemodialysis_survey?.dialysis_information,
                ...hemodialysis_survey?.weight_information,
                dialysis_db: hemodialysis_survey?.dialysis_db,
                crrt: hemodialysis_survey?.crrt,
                additional_information:
                  hemodialysis_survey?.additional_information,
              },
              initialDialysis
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.EMERGENCY:
        getEmergency({ user_id, patient_id }).then(({ data }) => {
          const { update_at, emergency_register_survey } = data;
          convertDataToStates(
            { update_at, ...emergency_register_survey },
            initialEmergency
          );
        });
        break;
      case MENU.CHILDBIRTH:
        getChildbirth({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, delivery_survey } = data;
            convertDataToStates(
              { update_at, ...delivery_survey },
              initialChildbirth
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.HOME_CARE:
        getHomeCare({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, homecare_survey } = data;
            convertDataToStates(
              { update_at, ...homecare_survey },
              initialHomeCare
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.DIET_NUTRITION:
        getDietNutrition({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, dietary_survey } = data;
            convertDataToStates(
              {
                update_at,
                birth: dietary_survey?.birth,
                classification: dietary_survey
                  ? dietary_survey.classification
                  : 1,
                select_meal: dietary_survey
                  ? JSON.parse(dietary_survey.select_meal)
                  : {},
                basic_meal: dietary_survey
                  ? JSON.parse(dietary_survey.basic_meal)
                  : {},
                therapuetic_diet: {
                  intestinal: dietary_survey
                    ? JSON.parse(dietary_survey.therapuetic_diet.intestinal)
                    : {},
                  kidney: dietary_survey
                    ? JSON.parse(dietary_survey.therapuetic_diet.kidney)
                    : {},
                  liver: dietary_survey
                    ? JSON.parse(dietary_survey.therapuetic_diet.liver)
                    : {},
                },
                controlled_diet: dietary_survey
                  ? JSON.parse(dietary_survey.controlled_diet)
                  : {},
                specifics: dietary_survey
                  ? JSON.parse(dietary_survey.specifics)
                  : {},
              },
              initialDietNutrition
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.PATIENTSGUIDE:
        getTransfusionAgree({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, transfusion_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                ...transfusion_confirmation,
              },
              initialTransfusionAgree
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.PATIENTCONSENTFORM:
        getColonoscopy({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, colono_scopy_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                ...colono_scopy_confirmation,
              },
              initialColonoscopy
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.TRANSFUSION_AGREE:
        getUpperEndoscopy({ user_id, patient_id })
          .then(({ data }) => {
            const { transfusion_confirmation } = data;
            convertDataToStates(
              {
                update_at: transfusion_confirmation?.update_at,

                interpreter_check1:
                  transfusion_confirmation?.interpreter_check1,
                interpreter_check2:
                  transfusion_confirmation?.interpreter_check2,
                interpreter_check3:
                  transfusion_confirmation?.interpreter_check3,
                interpreter_check4:
                  transfusion_confirmation?.interpreter_check4,

                consent: transfusion_confirmation
                  ? JSON.parse(transfusion_confirmation.consent)
                  : {},

                patient_sig: transfusion_confirmation?.patient_sig,
                relationship: transfusion_confirmation?.relationship,
                date: transfusion_confirmation?.date,
                dr_sig: transfusion_confirmation?.dr_sig,
                dr_date: transfusion_confirmation?.dr_date,
              },
              initialTransfusionAgreement
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CONSCIOUS:
        getNonSalary({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, uninsured_benefit_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                ...uninsured_benefit_confirmation,
              },
              initialNonSalary
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.DNR:
        getDNR({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, advance_directive_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                ...advance_directive_confirmation,
              },
              initialDNR
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.ADMISSION:
        getHospitalConfirm({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, hospital_confirm } = data;
            convertDataToStates(
              {
                update_at,
                name: hospital_confirm?.name,
                relationship: hospital_confirm?.relationship,
                signature: hospital_confirm?.signature,
              },
              initialHospitalConfirm
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.ADMISSIONGUIDE:
        convertDataToStates(
          initialCoreNursingSkillVideo,
          initialCoreNursingSkillVideo
        );
        break;
      case MENU.ADMISSION_CHECKLIST:
        getAdmCheckList({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, admission_check_list } = data;
            convertDataToStates(
              {
                update_at,
                education_list: admission_check_list
                  ? JSON.parse(admission_check_list.education_list)
                  : {},

                date: admission_check_list?.date,
                name: admission_check_list?.name,
                sig: admission_check_list?.sig,
              },
              initialAdmissionCheckList
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.FALL_PREVENTION:
        getFallConfirm({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, fall_confirm } = data;
            convertDataToStates(
              {
                update_at,
                fall_education: fall_confirm
                  ? JSON.parse(fall_confirm.fall_education)
                  : {},
                signature: fall_confirm?.signature,
                date: fall_confirm?.date,
                personnel_signature: fall_confirm?.personnel_signature,
              },
              initialFallConfirm
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CRRT_AGREEMENT:
        getCRRT({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, crrt_confirmation } = data;
            convertDataToStates(
              {
                update_at,

                no1_1: crrt_confirmation?.no1_1,
                no1_2: crrt_confirmation
                  ? JSON.parse(crrt_confirmation.no1_2)
                  : {},
                no1_3: crrt_confirmation?.no1_3,
                no2: crrt_confirmation?.no2,
                no3_1: crrt_confirmation
                  ? JSON.parse(crrt_confirmation.no3_1)
                  : {},

                date: crrt_confirmation?.date,
                relationship: crrt_confirmation?.relationship,
                name: crrt_confirmation?.name,
                sign: crrt_confirmation?.sign,
              },
              initialCRRT
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CENTRALVENOUS:
        getCentralVenous({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, cvc_insertion_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                no1: cvc_insertion_confirmation
                  ? JSON.parse(cvc_insertion_confirmation.no1)
                  : {},
                pt_bday: cvc_insertion_confirmation?.pt_bday,
                pt_contact: cvc_insertion_confirmation?.pt_contact,
                pt_name: cvc_insertion_confirmation?.pt_name,
                pt_sign: cvc_insertion_confirmation?.pt_sign,

                nok_bday: cvc_insertion_confirmation?.nok_bday,
                nok_contact: cvc_insertion_confirmation?.nok_contact,
                nok_name: cvc_insertion_confirmation?.nok_name,
                nok_sign: cvc_insertion_confirmation?.nok_sign,

                dr_name: cvc_insertion_confirmation?.dr_name,
                dr_sign: cvc_insertion_confirmation?.dr_sign,
              },
              initialCentralVenous
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.INTUBATIONAGREEMENT:
        getIntubationAgree({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, intubation_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                ...intubation_confirmation,
              },
              initialIntubationAgree
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.SUPPRESSOR:
        getSuppressor({ user_id, patient_id })
          .then(({ data }) => {
            const { update_at, restraints_confirmation } = data;
            convertDataToStates(
              {
                update_at,
                ...restraints_confirmation,
              },
              initialSuppressor
            );
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.DRUGCALCULATOR:
        getFall({ user_id, patient_id })
          .then(({ data }) => {
            const { contents } = data;
            const m_data = { ...data, contents: JSON.parse(contents) };
            convertDataToStates(m_data, initialFall);
          })
          .catch(e => onFail('An unknown error occurred.', e));
        break;
      case MENU.CORE_NURSING_SKILL_VIDEO:
        convertDataToStates(
          initialCoreNursingSkillVideo,
          initialCoreNursingSkillVideo
        );
        break;
      case MENU.CORE_NURSING_SKILL_VIDEO_EXEMPLE:
        convertDataToStates(
          initialCoreNursingSkillVideoExemple,
          initialCoreNursingSkillVideoExemple
        );
        break;
      default:
        setDefaultValues(null);
        break;
    }
  };

  return { onGetDefaultValues };
};

export default useDefaultValues;
